import classNames from "classnames";

export function Text({
  children,
  size = "md",
  weight = "regular",
  face = "serif",
  uppercase,
  className: classNameProp,
}: {
  children?: React.ReactNode;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "8xl"
    | "inherit";
  weight?: "light" | "regular" | "medium" | "bold";
  face?: "sans" | "serif" | "cursive";
  uppercase?: boolean;
  className?: string;
}) {
  const className = classNames(
    size === "xs" && "text-xs",
    size === "sm" && "text-sm",
    size === "lg" && "text-lg",
    size === "xl" && "text-xl",
    size === "2xl" && "text-2xl",
    size === "3xl" && "text-3xl",
    size === "4xl" && "text-4xl",
    size === "5xl" && "text-5xl",
    size === "6xl" && "text-6xl",
    size === "7xl" && "text-7xl",
    size === "8xl" && "text-8xl",
    weight === "light" && "font-light",
    weight === "regular" && "font-normal",
    weight === "medium" && "font-medium",
    weight === "bold" && "font-bold",
    face === "sans" && "font-sans",
    face === "serif" && "font-serif",
    face === "cursive" && "font-cursive",
    uppercase && "uppercase",
    classNameProp
  );

  return <span className={className}>{children}</span>;
}
