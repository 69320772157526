"use client";
import { CopyIcon } from "./customIcons";

export function CopyIconWithText({ text }: { text: string }) {
  function copy() {
    navigator.clipboard.writeText(text || "");
    // TODO(dankins): pop toast
  }
  return <CopyIcon className="cursor-pointer" onClick={copy} />;
}
