"use client";

import { JSXElementConstructor, useState } from "react";
import { ButtonProps } from "./BaseButton";
import { PrimaryButton } from "./PrimaryButton";
import { FormState } from "@cakemembers/types";
import { SecondaryButton } from "./SecondaryButton";
import { TertiaryButton } from "./TertiaryButton";
import { CircleButton } from "./CircleButton";
import { GhostButton } from "./GhostButton";
import { CircleButtonSecondary } from "./CircleButtonSecondary";
import { useRouter } from "next/navigation";

export type ActionButtonProps = {
  action(): Promise<FormState>;
  as?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "circle"
    | "circle-secondary"
    | "ghost";
};
export function ActionButton({
  action,
  children,
  ...rest
}: ButtonProps & ActionButtonProps) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  async function handleClick() {
    setLoading(true);
    try {
      const result = await action();
      if (result && result.status === "success" && result.redirect) {
        router.push(result.redirect);
      }
    } finally {
      setLoading(false);
    }
  }

  let Component: JSXElementConstructor<ButtonProps> = PrimaryButton;
  switch (rest.as) {
    case "primary":
      Component = PrimaryButton;
      break;
    case "secondary":
      Component = SecondaryButton;
      break;
    case "tertiary":
      Component = TertiaryButton;
      break;
    case "circle":
      Component = CircleButton;
      break;
    case "circle-secondary":
      Component = CircleButtonSecondary;
      break;
    case "ghost":
      Component = GhostButton;
      break;
  }
  return (
    <Component {...rest} onClick={handleClick} loading={loading}>
      {children}
    </Component>
  );
}
