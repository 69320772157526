"use client";

import { ErrorBoundary } from "react-error-boundary";
import { Heading3, Heading4 } from "../text";

export function InterceptModalErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ErrorBoundary fallbackRender={Fallback}>{children}</ErrorBoundary>;
}

function Fallback({
  error,
  resetErrorBoundary,
}: {
  error: Error & { digest?: string };
  resetErrorBoundary: () => void;
}) {
  return (
    <div className="p-6">
      <Heading4 className="text-xl text-center mb-4">
        Application error: a server side
        <br /> exception has occurred
      </Heading4>
      {error.digest && <Heading3>Digest: {error.digest}</Heading3>}
    </div>
  );
}
