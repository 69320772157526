"use client";

import { PrimaryButton, SecondaryButton } from "../buttons";
import { FormState, FormSuccess } from "@cakemembers/types";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { JSXElementConstructor, useEffect, useRef, useState } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { Paragraph3, Text } from "../text";

export type { FormState, FormSuccess } from "@cakemembers/types";

const initialState: FormState = {
  status: "start",
};

export function FormAction({
  action,
  cta,
  inline = false,
  children,
  className,
  secondaryButton,
  disabled,
  clearOnSuccess = true,
  submitButton = DefaultSubmitFormButton,
  requireConfirmation,
  confirmationText,
  confirmationCta,
  confirmationReject,
  onSuccess,
}: {
  action(previousState: FormState, formData: FormData): Promise<FormState>;
  cta: React.ReactNode;
  inline?: boolean;
  children?: React.ReactNode;
  className?: string;
  secondaryButton?: React.ReactNode;
  disabled?: boolean;
  clearOnSuccess?: boolean;
  requireConfirmation?: boolean;
  confirmationText?: React.ReactNode;
  confirmationCta?: React.ReactNode;
  confirmationReject?: React.ReactNode;
  submitButton?: JSXElementConstructor<{
    formState: FormState;
    cta: React.ReactNode;
    formValid: boolean;
    secondaryButton?: React.ReactNode;
    disabled?: boolean;
    requireConfirmation?: boolean;
    confirmationText?: React.ReactNode;
    confirmationCta?: React.ReactNode;
    confirmationReject?: React.ReactNode;
  }>;
  onSuccess?: (formState: FormSuccess) => void;
}) {
  const [formValid, setFormValid] = useState(false);
  const [state, formAction] = useFormState(handler, initialState);
  const formRef = useRef<HTMLFormElement>(null);
  const router = useRouter();

  async function handler(
    previousState: FormState,
    formData: FormData
  ): Promise<FormState> {
    const result = await action(previousState, formData);

    if (result.status === "success" && formRef && formRef.current) {
      clearOnSuccess && formRef.current.reset();
      if (result.redirect) {
        router.push(result.redirect);
      }
      onSuccess && onSuccess(result);
    }
    return result;
  }

  useEffect(() => {
    // check if form is valid on mount
    if (formRef && formRef.current) {
      setFormValid(formRef.current.checkValidity());
    }
  }, [formRef]);

  function handleChange() {
    if (formRef && formRef.current) {
      const newFormValid = formRef.current.checkValidity();
      if (newFormValid !== formValid) {
        setFormValid(newFormValid);
      }
    }
  }

  const SubmitComponent = submitButton;

  return (
    <form
      action={formAction}
      className={classNames(
        inline && "flex flex-row items-center gap-2",
        className
      )}
      ref={formRef}
      onChange={handleChange}
    >
      {children}

      <SubmitComponent
        formState={state}
        cta={cta}
        formValid={formValid}
        secondaryButton={secondaryButton}
        requireConfirmation={requireConfirmation}
        confirmationText={confirmationText}
        confirmationCta={confirmationCta}
        confirmationReject={confirmationReject}
      />

      {state.status === "error" && (
        <Paragraph3 className="text-secondary">{state.message}</Paragraph3>
      )}
      {state.status === "success" && state.message && (
        <Paragraph3>{state.message}</Paragraph3>
      )}
    </form>
  );
}

export function DefaultSubmitFormButton({
  formState,
  cta,
  formValid,
  secondaryButton,
  disabled,
  requireConfirmation,
  confirmationText = "Are you sure you want to perform this action?",
  confirmationCta = "Confirm",
  confirmationReject = "Cancel",
}: {
  formState: FormState;
  cta: React.ReactNode;
  formValid: boolean;
  secondaryButton?: React.ReactNode;
  disabled?: boolean;
  requireConfirmation?: boolean;
  confirmationText?: React.ReactNode;
  confirmationCta?: React.ReactNode;
  confirmationReject?: React.ReactNode;
}) {
  const [confirmationState, setConfirmationState] = useState<
    "start" | "confirm"
  >("start");
  const status = useFormStatus();

  if (requireConfirmation === undefined) {
    return (
      <div className="flex flex-row justify-center gap-2 ">
        <PrimaryButton
          type="submit"
          loading={status.pending}
          disabled={status.pending || !formValid || disabled}
        >
          {cta}
        </PrimaryButton>
        {secondaryButton}
      </div>
    );
  }

  if (confirmationState === "confirm") {
    return (
      <div>
        <Text className="text-primary/60">{confirmationText}</Text>
        <div className="flex flex-row justify-center gap-2 ">
          <PrimaryButton
            type="submit"
            loading={status.pending}
            disabled={status.pending || !formValid || disabled}
          >
            {confirmationCta}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => setConfirmationState("start")}
            disabled={status.pending}
          >
            {confirmationReject}
          </SecondaryButton>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-center gap-2">
      <PrimaryButton
        onClick={() => setConfirmationState("confirm")}
        disabled={status.pending || !formValid || disabled}
      >
        {confirmationCta}
      </PrimaryButton>
      {secondaryButton}
    </div>
  );
}
