"use client";
import Link from "next/link";
import styles from "./InterceptModal.module.scss";
import { useEffect } from "react";
import { Heading4 } from "../text";
import { CircleButton } from "../buttons";
import { CloseIcon } from "../icons";
import { InterceptModalErrorBoundary } from "./InterceptModalErrorBoundary";
import classNames from "classnames";

export function InterceptModal({
  title,
  returnHref,
  children,
  stickyModalHeader,
}: {
  children: React.ReactNode;
  returnHref: string;
  title?: string;
  stickyModalHeader?: boolean;
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const mainElements = document.getElementsByTagName("main");
    if (mainElements && mainElements.length > 0) {
      mainElements[0].style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
      const mainElements = document.getElementsByTagName("main");
      if (mainElements && mainElements.length > 0) {
        mainElements[0].style.overflow = "auto";
      }
    };
  }, []);

  return (
    <div className={styles.InterceptModal}>
      <Link href={returnHref} className="cursor-default">
        <div></div>
      </Link>
      <div>
        {title && (
          <div
            className={classNames(
              stickyModalHeader && "sticky top-0 z-50 bg-white",
              "p-4 flex flex-row items-center"
            )}
          >
            {title && <Heading4 className="grow">{title}</Heading4>}

            <CircleButton icon={<CloseIcon />} href={returnHref} />
          </div>
        )}
        <InterceptModalErrorBoundary>{children}</InterceptModalErrorBoundary>
      </div>
    </div>
  );
}
