"use client";

import Link from "next/link";
import { useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";

const exceptionArray: string[] = [
  process.env.NEXT_PUBLIC_SITE_URL!,
  "https://www.lecatch.com",
];

export function ExternalProductLinkHandler({
  children,
  targetUrl,
}: {
  children: React.ReactNode;
  targetUrl: string;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [encodedTargetUrl, setEncodedTargetUrl] = useState<string | null>(null);
  const [encodedReturnUrl, setEncodedReturnUrl] = useState<string | null>(null);

  useEffect(() => {
    setEncodedTargetUrl(encodeURI(targetUrl));

    const currentUrl = `${pathname}?${searchParams.toString()}`;
    setEncodedReturnUrl(encodeURIComponent(currentUrl));
  }, [pathname, searchParams, targetUrl]);

  if (!encodedTargetUrl || !encodedReturnUrl) {
    return null;
  }

  const newHref = `${pathname}?${searchParams.toString()}&modal=process-shop-link&target=${encodedTargetUrl}&returnUrl=${encodedReturnUrl}`;

  // Check if any of the exceptionArray URLs are part of the targetUrl
  const isInExceptionArray = exceptionArray.some((exceptionUrl) =>
    targetUrl.startsWith(exceptionUrl)
  );

  return isInExceptionArray ? (
    <Link target="_blank" href={targetUrl}>
      {children}
    </Link>
  ) : (
    <Link href={newHref} scroll={false}>
      {children}
    </Link>
  );
}
