import React from "react";
import { BaseButton } from "./BaseButton";

export function CircleButtonSecondary({
  children,
  ...props
}: React.ComponentProps<typeof BaseButton>) {
  return (
    <BaseButton
      {...props}
      rounded="full"
      background={props.background || "transparent"}
      textColor={props.textColor || "text-primary"}
      border={props.border || "border-primary"}
      disabledClass={
        "disabled:primary/30 disabled:text-primary/30 disabled:border-primary/30"
      }
      activeClass={
        "border active:text-button-active active:border-button-active"
      }
      hoverClass="border hover:text-primary/80 hover:border-primary/80"
      uppercase
      padding={props.padding || "p-2"}
    >
      {children}
    </BaseButton>
  );
}
